<template>
  <div class="div_pageunqualit" :style="{ height: elementHeight }">
    <div class="div_unqualit">
      <div class="div_bigplan_head">
        <div class="div_plan">
          <span
            ><span class="plan_span_img"
              ><img src="../../assets/water/plan.png" /> </span
            >&nbsp;不合格跟踪</span
          >
        </div>
      </div>
      <div class="div_bigplan_hr">
        <hr />
      </div>
      <div class="div_unqua_btn">
        <ul class="ul_btn">
          <template v-for="btn in btnarr">
            <li
              :style="
                btn.code == status
                  ? 'border-bottom: 2px solid #578CFD;color: #578CFD;'
                  : ''
              "
              @click="btnarrClick(btn.code)"
              :key="btn.code"
            >
              <span>{{ btn.label }}</span>
            </li>
          </template>
        </ul>
        <ul class="ul_unquabtn" v-show="status == 1">
          <li>
            <span class="btn_UptReport" @click="openReport"
              ><i class="el-icon-edit-outline"></i>修改报告</span
            >
          </li>
          <li>
            <span class="btn_AllSend" @click="AllSend">全部发出报告</span>
          </li>
        </ul>
      </div>
      <div class="div_unqua_hr">
        <hr />
      </div>
      <div class="div_unquatab" v-show="status == 1">
        <el-table
          :data="
            Unqualified.filter((e) => e.trackState == 0).slice(
              (UnqualifiedcurrentPage - 1) * UnqualifiedpageSize,
              UnqualifiedcurrentPage * UnqualifiedpageSize
            )
          "
          width="100%"
          stripe
          border
          current-row-key
          highlight-current-row
          class="tab_unqua"
          max-height="640px"
          @row-click="reportclick"
        >
          <el-table-column label="序号" type="index" width="60" align="center">
          </el-table-column>
          <el-table-column label="检查编号" prop="evaNo" align="center">
          </el-table-column>
          <el-table-column
            label="检查项目"
            prop="evaItem"
            width="200px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="检查内容"
            prop="evaContent"
            width="300px"
            align="center"
          >
          </el-table-column>
          <el-table-column label="次数" prop="trackTimes" align="center">
          </el-table-column>
          <el-table-column
            label="不合格描述"
            prop="auditDescribe"
            align="center"
          >
          </el-table-column>
          <el-table-column label="扣掉分数" prop="auditScore" align="center">
          </el-table-column>
          <el-table-column label="发送人" prop="sendProvenName" align="center">
          </el-table-column>
          <el-table-column
            label="发送时间"
            prop="sendProvenDate"
            :formatter="dateFormat"
            align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="120"
            align="center"
          >
            <template slot-scope="scope">
              <span class="btn_send" @click="OneSend(scope.row)">发出报告</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="unquaPage">
          <div style="float: right">
            <el-pagination
              @size-change="UnqualifiedhandleSizeChange"
              @current-change="UnqualifiedhandleCurrentChange"
              :current-page="UnqualifiedcurrentPage"
              :page-sizes="[5, 10, 20, 50]"
              :page-size="UnqualifiedpageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="Unqualified.filter((e) => e.trackState == 0).length"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <div class="div_unquatab" v-show="status == 2">
        <el-table
          :data="
            Unqualified.filter((e) => e.trackState == 1).slice(
              (UnqualifiedcurrentPage1 - 1) * UnqualifiedpageSize1,
              UnqualifiedcurrentPage1 * UnqualifiedpageSize1
            )
          "
          width="100%"
          stripe
          border
          current-row-key
          highlight-current-row
          class="tab_unqua"
          max-height="640px"
        >
          <el-table-column label="序号" type="index" width="60" align="center">
          </el-table-column>
          <el-table-column label="检查编号" prop="evaNo" align="center">
          </el-table-column>
          <el-table-column
            label="检查项目"
            prop="evaItem"
            width="200px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="检查内容"
            prop="evaContent"
            width="300px"
            align="center"
          >
          </el-table-column>
          <el-table-column label="次数" prop="trackTimes" align="center">
          </el-table-column>
          <el-table-column
            label="不合格描述"
            prop="auditDescribe"
            align="center"
          >
          </el-table-column>
          <el-table-column label="扣掉分数" prop="auditScore" align="center">
          </el-table-column>
          <el-table-column label="发送人" prop="sendTrackName" align="center">
          </el-table-column>
          <el-table-column
            label="发送时间"
            prop="sendTrackDate"
            :formatter="dateFormat"
            align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="120"
            align="center"
          >
            <template slot-scope="scope">
              <span class="btn_send" @click="TakeMeasure(scope.row)"
                >提交措施</span
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="unquaPage">
          <div style="float: right">
            <el-pagination
              @size-change="UnqualifiedhandleSizeChange1"
              @current-change="UnqualifiedhandleCurrentChange1"
              :current-page="UnqualifiedcurrentPage1"
              :page-sizes="[5, 10, 20, 50]"
              :page-size="UnqualifiedpageSize1"
              layout="total, sizes, prev, pager, next, jumper"
              :total="Unqualified.filter((e) => e.trackState == 1).length"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <div class="div_unquatab" v-show="status == 3">
        <el-table
          :data="
            Unqualified.filter((e) => e.trackState == 2).slice(
              (UnqualifiedcurrentPage2 - 1) * UnqualifiedpageSize2,
              UnqualifiedcurrentPage2 * UnqualifiedpageSize2
            )
          "
          width="100%"
          stripe
          border
          current-row-key
          highlight-current-row
          class="tab_unqua"
          max-height="640px"
        >
          <el-table-column label="序号" type="index" width="60" align="center">
          </el-table-column>
          <el-table-column label="检查编号" prop="evaNo" align="center">
          </el-table-column>
          <el-table-column
            label="检查项目"
            prop="evaItem"
            width="200px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="检查内容"
            prop="evaContent"
            width="300px"
            align="center"
          >
          </el-table-column>
          <el-table-column label="次数" prop="trackTimes" align="center">
          </el-table-column>
          <el-table-column
            label="不合格描述"
            prop="auditDescribe"
            align="center"
          >
          </el-table-column>
          <el-table-column label="扣掉分数" prop="auditScore" align="center">
          </el-table-column>
          <el-table-column label="发送人" prop="sendTrackName" align="center">
          </el-table-column>
          <el-table-column
            label="发送时间"
            prop="sendTrackDate"
            :formatter="dateFormat"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="纠正措施"
            prop="correctiveMeasures"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="纠正期限"
            prop="correctiveDeadlines"
            :formatter="dateFormat"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="提交措施人"
            prop="sendCorrectName"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="提交措施时间"
            prop="sendCorrectDate"
            :formatter="dateFormat"
            align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="120"
            align="center"
          >
            <template slot-scope="scope">
              <span class="btn_send" @click="SubmitVerify(scope.row)"
                >提交验证</span
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="unquaPage">
          <div style="float: right">
            <el-pagination
              @size-change="UnqualifiedhandleSizeChange2"
              @current-change="UnqualifiedhandleCurrentChange2"
              :current-page="UnqualifiedcurrentPage2"
              :page-sizes="[5, 10, 20, 50]"
              :page-size="UnqualifiedpageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="Unqualified.filter((e) => e.trackState == 2).length"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <div class="div_unquatab" v-show="status == 4">
        <el-table
          :data="
            Unqualified.filter((e) => e.trackState == 3).slice(
              (UnqualifiedcurrentPage3 - 1) * UnqualifiedpageSize3,
              UnqualifiedcurrentPage3 * UnqualifiedpageSize3
            )
          "
          width="100%"
          stripe
          border
          current-row-key
          highlight-current-row
          class="tab_unqua"
          max-height="640px"
        >
          <el-table-column label="序号" type="index" width="60" align="center">
          </el-table-column>
          <el-table-column label="检查编号" prop="evaNo" align="center">
          </el-table-column>
          <el-table-column
            label="检查项目"
            prop="evaItem"
            width="200px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="检查内容"
            prop="evaContent"
            width="300px"
            align="center"
          >
          </el-table-column>
          <el-table-column label="次数" prop="trackTimes" align="center">
          </el-table-column>
          <el-table-column
            label="不合格描述"
            prop="auditDescribe"
            align="center"
          >
          </el-table-column>
          <el-table-column label="扣掉分数" prop="auditScore" align="center">
          </el-table-column>
          <el-table-column label="发送人" prop="sendTrackName" align="center">
          </el-table-column>
          <el-table-column
            label="发送时间"
            prop="sendTrackDate"
            :formatter="dateFormat"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="纠正措施"
            prop="correctiveMeasures"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="纠正期限"
            prop="correctiveDeadlines"
            :formatter="dateFormat"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="提交措施人"
            prop="sendCorrectName"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="提交措施时间"
            prop="sendCorrectDate"
            :formatter="dateFormat"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="提交验证人"
            prop="sendProvenName"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="提交验证时间"
            prop="sendProvenDate"
            :formatter="dateFormat"
            align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="120"
            align="center"
          >
            <template slot-scope="scope">
              <span class="btn_send" @click="ConfirmVerify(scope.row)"
                >确定验证</span
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="unquaPage">
          <div style="float: right">
            <el-pagination
              @size-change="UnqualifiedhandleSizeChange3"
              @current-change="UnqualifiedhandleCurrentChange3"
              :current-page="UnqualifiedcurrentPage3"
              :page-sizes="[5, 10, 20, 50]"
              :page-size="UnqualifiedpageSize3"
              layout="total, sizes, prev, pager, next, jumper"
              :total="Unqualified.filter((e) => e.trackState == 3).length"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <div class="div_unquatab" v-show="status == 5">
        <el-table
          :data="
            Unqualified.filter((e) => e.trackState == 4).slice(
              (UnqualifiedcurrentPage4 - 1) * UnqualifiedpageSize4,
              UnqualifiedcurrentPage4 * UnqualifiedpageSize4
            )
          "
          width="100%"
          stripe
          border
          current-row-key
          highlight-current-row
          class="tab_unqua"
          max-height="640px"
        >
          <el-table-column label="序号" type="index" width="60" align="center">
          </el-table-column>
          <el-table-column label="检查编号" prop="evaNo" align="center">
          </el-table-column>
          <el-table-column
            label="检查项目"
            prop="evaItem"
            width="200px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="检查内容"
            prop="evaContent"
            width="300px"
            align="center"
          >
          </el-table-column>
          <el-table-column label="次数" prop="trackTimes" align="center">
          </el-table-column>
          <el-table-column
            label="不合格描述"
            prop="auditDescribe"
            align="center"
          >
          </el-table-column>
          <el-table-column label="扣掉分数" prop="auditScore" align="center">
          </el-table-column>
          <el-table-column label="发送人" prop="sendProvenName" align="center">
          </el-table-column>
          <el-table-column
            label="发送时间"
            prop="sendTrackDate"
            :formatter="dateFormat"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="纠正措施"
            prop="correctiveMeasures"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="纠正期限"
            prop="correctiveDeadlines"
            :formatter="dateFormat"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="提交措施人"
            prop="sendCorrectName"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="提交措施时间"
            prop="sendCorrectDate"
            :formatter="dateFormat"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="提交验证人"
            prop="sendProvenName"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="提交验证时间"
            prop="sendProvenDate"
            :formatter="dateFormat"
            align="center"
          >
          </el-table-column>
          <el-table-column label="验证情况" prop="provenContent" align="center">
          </el-table-column>
          <el-table-column label="是否通过验证" prop="isPass" align="center">
          </el-table-column>
          <el-table-column label="验证人" prop="sureProvenName" align="center">
          </el-table-column>
          <el-table-column
            label="验证时间"
            prop="sureProvenDate"
            :formatter="dateFormat"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="现场证据"
            align="center"
            fixed="right"
            width="120"
          >
            <template scope="scope">
              <span class="btn_zjck" @click="selUnquaImg(scope.row.id)"
                >查看</span
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="unquaPage">
          <div style="float: right">
            <el-pagination
              @size-change="UnqualifiedhandleSizeChange4"
              @current-change="UnqualifiedhandleCurrentChange4"
              :current-page="UnqualifiedcurrentPage4"
              :page-sizes="[5, 10, 20, 50]"
              :page-size="UnqualifiedpageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="Unqualified.filter((e) => e.trackState == 4).length"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="修改报告"
      :visible.sync="dialoguptReport"
      width="450px"
      :close-on-click-modal="false"
    >
      <el-form ref="uptReport" :model="{ uptReport }">
        <p class="p_DetailDesc1">不合格描述：</p>

        <textarea class="txtdesc" v-model="uptReport.auditDescribe"></textarea>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <span class="span_qx" @click="qxclick">取消</span>
        <span class="span_qd" @click="qdclick">确 定</span>
        <el-button type="primary" style="visibility: hidden">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="提交措施"
      :visible.sync="dialoguptReport1"
      width="390px"
      :close-on-click-modal="false"
    >
      <el-form ref="uptReport1" :model="{ uptReport1 }" label-width="100px">
        <el-form-item label="纠正措施：">
          <el-input
            style="width: 220px"
            v-model="uptReport1.Measures"
            placeholder="请输入纠正措施"
          ></el-input>
        </el-form-item>
        <el-form-item label="纠正日期：">
          <el-date-picker
            v-model="uptReport1.Dealtime"
            align="right"
            type="date"
            :editable="false"
            placeholder="选择纠正日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <span class="span_qx1" @click="qxclick1">取消</span>
        <span class="span_qd1" @click="qdclick1">确 定</span>
        <el-button type="primary" style="visibility: hidden; width: 20px"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="确定"
      :visible.sync="dialoguptReport2"
      width="390px"
      :close-on-click-modal="false"
    >
      <el-form ref="uptReport1" :model="{ uptReport2 }" label-width="100px">
        <el-form-item label="验证情况：">
          <el-input
            style="width: 220px"
            v-model="uptReport2.Content"
            placeholder="请输入验证情况"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否通过：">
          <el-select
            class="el-input__diatext"
            placeholder="请选择是否通过"
            v-model="uptReport2.isOk"
          >
            <el-option label="是" value="是"> </el-option>
            <el-option label="否" value="否"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="现场证据：">
          <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :before-upload="beforeAvatarUpload"
            :on-remove="handleRemove"
            :http-request="UploadFile"
            ref="upload"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <el-dialog :visible.sync="ImgVisible" :append-to-body="true">
            <img width="100%" :src="dialogImageUrl" alt="" class="avatar" />
          </el-dialog>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <span class="span_qx1" @click="qxclick2">取消</span>
        <span class="span_qd1" @click="qdclick2">确 定</span>
        <el-button type="primary" style="visibility: hidden; width: 20px"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="图片信息" :visible.sync="dialogPic" width="390px">
      <el-form>
        <el-form-item>
          <template v-for="item in ShowPicList">
            <div :key="item.id" @click="ckpic(item.src)" class="PicList">
              <img :src="item.src" />
            </div>
          </template>
        </el-form-item>
      </el-form>
    </el-dialog>
    
    <el-dialog :visible.sync="ImgVisible1" :append-to-body="true">
      <img width="100%" :src="dialogImageUrl1" alt="" class="avatar" />
    </el-dialog>
  </div>
</template>
<script>
import { getHeader, putHeader, postHeader } from "../../api/http";
import moment from "moment";
import axios from "axios";
export default {
  data() {
    return {
      elementHeight:
        document.documentElement.clientHeight - 81.5 - 46 - 12 + "px",
      UserId: 0,
      UserNo: "",
      UserName: "",
      compId: "",
      ImgVisible: false,
      status: 1,
      btnarr: [
        { code: 1, label: "待发出报告" },
        { code: 2, label: "待提交措施" },
        { code: 3, label: "待提交验证" },
        { code: 4, label: "待确定验证" },
        { code: 5, label: "已通过验证" },
      ],
      Unqualified: [],
      SmallPlanId: 0,
      UnqualifiedcurrentPage: 1,
      UnqualifiedpageSize: 5,
      UnqualifiedcurrentPage1: 1,
      UnqualifiedpageSize1: 5,
      UnqualifiedcurrentPage2: 1,
      UnqualifiedpageSize2: 5,
      UnqualifiedcurrentPage3: 1,
      UnqualifiedpageSize3: 5,
      UnqualifiedcurrentPage4: 1,
      UnqualifiedpageSize4: 5,
      dialoguptReport: false,
      dialoguptReport1: false,
      dialoguptReport2: false,
      uptReport: {
        id: 0,
        auditDescribe: "",
      },
      uptReport1: {
        id: 0,
        Measures: "",
        Dealtime: "",
      },
      uptReport2: {
        id: 0,
        Content: "",
        isOk: "是",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      dialogImageUrl: "",
      ImgFile: [],
      dialogPic: false,
      ShowPicList: [],
      ImgVisible1: false,
      dialogImageUrl1: "",
    };
  },
  mounted() {
    this.UserId = JSON.parse(localStorage.getItem("User")).id;
    this.UserNo = JSON.parse(localStorage.getItem("User")).userNo;
    this.UserName = JSON.parse(localStorage.getItem("User")).userName;
    this.compId = JSON.parse(localStorage.getItem("User")).compId;
    //console.log(this.$route)
  },
  activated() {
    this.SmallPlanId =
      this.$route.params == undefined ? 0 : this.$route.params.SmallPlanId;
    this.UnqualifiedcurrentPage = 1;
    this.UnqualifiedpageSize = 5;
    this.UnqualifiedcurrentPage1 = 1;
    this.UnqualifiedpageSize1 = 5;
    this.UnqualifiedcurrentPage2 = 1;
    this.UnqualifiedpageSize2 = 5;
    this.UnqualifiedcurrentPage3 = 1;
    (this.UnqualifiedpageSize3 = 5), (this.UnqualifiedcurrentPage4 = 1);
    this.UnqualifiedpageSize4 = 5;
    if (this.SmallPlanId != 0 && this.SmallPlanId != undefined) {
      this.GetUnqualified();
    }
    this.status = 1;
  },
  methods: {
    UnqualifiedhandleSizeChange(val) {
      this.UnqualifiedcurrentPage = 1;
      this.UnqualifiedpageSize = val;
    },
    UnqualifiedhandleCurrentChange(val) {
      this.UnqualifiedcurrentPage = val;
    },
    UnqualifiedhandleSizeChange1(val) {
      this.UnqualifiedcurrentPage1 = 1;
      this.UnqualifiedpageSize1 = val;
    },
    UnqualifiedhandleCurrentChange1(val) {
      this.UnqualifiedcurrentPage1 = val;
    },
    UnqualifiedhandleSizeChange2(val) {
      this.UnqualifiedcurrentPage2 = 1;
      this.UnqualifiedpageSize2 = val;
    },
    UnqualifiedhandleCurrentChange2(val) {
      this.UnqualifiedcurrentPage2 = val;
    },
    UnqualifiedhandleSizeChange3(val) {
      this.UnqualifiedcurrentPage3 = 1;
      this.UnqualifiedpageSize3 = val;
    },
    UnqualifiedhandleCurrentChange3(val) {
      this.UnqualifiedcurrentPage3 = val;
    },
    UnqualifiedhandleSizeChange4(val) {
      this.UnqualifiedcurrentPage4 = 1;
      this.UnqualifiedpageSize4 = val;
    },
    UnqualifiedhandleCurrentChange4(val) {
      this.UnqualifiedcurrentPage4 = val;
    },
    dateFormat(row, column) {
      if (
        column.property == "sendProvenDate" ||
        column.property == "correctiveDeadlines" ||
        column.property == "sendCorrectDate" ||
        column.property == "sendTrackDate" ||
        column.property == "sureProvenDate"
      ) {
        var date = row[column.property];
        if (date == undefined) {
          return "";
        }
        return moment(date).format("YYYY-MM-DD");
      }
    },
    btnarrClick(e) {
      this.status = e;
      this.UnqualifiedcurrentPage = 1;
      this.UnqualifiedpageSize = 5;
      this.UnqualifiedcurrentPage1 = 1;
      this.UnqualifiedpageSize1 = 5;
      this.UnqualifiedcurrentPage2 = 1;
      this.UnqualifiedpageSize2 = 5;
      this.UnqualifiedcurrentPage3 = 1;
      this.UnqualifiedpageSize3 = 5;
      this.UnqualifiedcurrentPage4 = 1;
      this.UnqualifiedpageSize4 = 5;
      this.GetUnqualified();
    },
    GetUnqualified() {
      getHeader("/WsmTrackUnqualified/" + this.SmallPlanId, null, this.compId)
        .then((resp) => {
          if (resp.result == true) {
            resp.value.forEach((e) => {
              e.isPass = e.isPass == true ? "是" : "否";
            });
            this.Unqualified = resp.value;
          }
        })
        .catch((error) => {
          console.log("error=" + JSON.stringify(error));
        });
    },
    openReport() {
      if (this.uptReport.id == 0) {
        this.$message({
          message: "请选择要修改的报告！",
          type: "warning",
        });
        return;
      }
      this.dialoguptReport = true;
    },
    reportclick(e) {
      this.uptReport.id = e.id;
      this.uptReport.auditDescribe = e.auditDescribe;
    },
    qxclick() {
      this.dialoguptReport = false;
    },
    qdclick() {
      this.Unqualified.filter(
        (e) => e.id == this.uptReport.id
      )[0].auditDescribe = this.uptReport.auditDescribe;
      this.dialoguptReport = false;
    },
    OneSend(e) {
      putHeader(
        "/WsmTrackUnqualified/PutOne?id=" +
          e.id +
          "&describe=" +
          e.auditDescribe +
          "&userid=" +
          this.UserId,
        null,
        this.compId
      )
        .then((res) => {
          if (res.result == true) {
            this.$message({
              message: "报告发出成功",
              type: "success",
            });
            this.GetUnqualified();
            this.uptReport = {
              id: 0,
              auditDescribe: "",
            };
          } else {
            this.$message.error("报告发出失败");
          }
        })
        .catch(() => {
          this.$message.error("报告发出失败");
        });
    },
    AllSend: async function () {
      if (this.Unqualified.filter((e) => e.trackState == 0).length == 0) {
        this.$message({
          message: "没有可发出的报告！",
          type: "warning",
        });
        return;
      }
      var list = this.Unqualified.filter((e) => e.trackState == 0);
      for (let i = 0; i < list.length; i++) {
        var b = true;
        await putHeader(
          "/WsmTrackUnqualified/PutOne?id=" +
            list[i].id +
            "&describe=" +
            list[i].auditDescribe +
            "&userid=" +
            this.UserId,
          null,
          this.compId
        )
          .then((res) => {
            if (res.result != true) {
              throw new Error("报告发出失败");
            }
          })
          .then(() => {
            if (i == list.length - 1) {
              this.$message({
                message: "报告发出成功",
                type: "success",
              });
              this.GetUnqualified();
              this.uptReport = {
                id: 0,
                auditDescribe: "",
              };
            }
          })
          .catch((error) => {
            b = false;
            this.$message.error(error.message);
          });
        if (!b) {
          return false;
        }
      }
    },
    qxclick1() {
      this.dialoguptReport1 = false;
    },
    qdclick1() {
      if (this.uptReport1.Measures == "") {
        this.$message({
          message: "请输入纠正措施",
          type: "warning",
        });
        return;
      }
      if (this.uptReport1.Dealtime == "") {
        this.$message({
          message: "请输入纠正日期",
          type: "warning",
        });
        return;
      }
      var Dealtime = new Date(this.uptReport1.Dealtime).Tostring("yyyy-MM-dd");
      putHeader(
        "/WsmTrackUnqualified/PutTwo?id=" +
          this.uptReport1.id +
          "&Measures=" +
          this.uptReport1.Measures +
          "&Dealtime=" +
          Dealtime +
          "&userid=" +
          this.UserId,
        null,
        this.compId
      )
        .then((res) => {
          if (res.result == true) {
            this.$message({
              message: "措施提交成功",
              type: "success",
            });
            this.dialoguptReport1 = false;
            this.GetUnqualified();
            this.uptReport1 = {
              id: 0,
              Measures: "",
              Dealtime: "",
            };
          } else {
            this.$message.error("措施提交失败");
          }
        })
        .catch(() => {
          this.$message.error("措施提交失败");
        });
    },
    TakeMeasure(e) {
      this.dialoguptReport1 = true;
      this.uptReport1.id = e.id;
      this.uptReport1.Measures = "";
      this.uptReport1.Dealtime = "";
    },
    SubmitVerify(e) {
      putHeader(
        "/WsmTrackUnqualified/PutThere?id=" + e.id + "&userid=" + this.UserId,
        null,
        this.compId
      )
        .then((res) => {
          if (res.result == true) {
            this.$message({
              message: "验证提交成功",
              type: "success",
            });
            this.GetUnqualified();
          } else {
            this.$message.error("验证提交失败");
          }
        })
        .catch(() => {
          this.$message.error("验证提交失败");
        });
    },
    ConfirmVerify(e) {
      this.dialoguptReport2 = true;
      this.uptReport2.id = e.id;
      this.uptReport2.Content = "";
      this.uptReport2.isOk = "是";
      if (this.$refs.upload != null) {
        this.$refs.upload.clearFiles();
      }
    },
    qxclick2() {
      this.dialoguptReport2 = false;
    },
    qdclick2() {
      if (this.uptReport2.Content == "") {
        this.$message({
          message: "请输入验证情况",
          type: "warning",
        });
        return;
      }
      var isOk = this.uptReport2.isOk == "是" ? true : false;
      if (this.ImgFile.length > 0) {
        var arrstr = [];
        this.ImgFile.forEach((e) => {
          arrstr.push({
            Picname: e.NewName,
            Relationid: this.uptReport2.id,
            Saveuno: this.UserNo,
            Savename: this.UserName,
          });
        });
        postHeader(
          "/WsmTrackUnquaPic",
          { spicDtos: JSON.stringify(arrstr) },
          this.compId
        )
          .then((resp) => {
            if (resp.result == true) {
              this.ImgFile = [];
              this.$refs.upload.clearFiles();
              putHeader(
                "/WsmTrackUnqualified/PutFour?id=" +
                  this.uptReport2.id +
                  "&Content=" +
                  this.uptReport2.Content +
                  "&isOk=" +
                  isOk +
                  "&userid=" +
                  this.UserId,
                null,
                this.compId
              )
                .then((res) => {
                  if (res.result == true) {
                    this.$message({
                      message: "验证确定完成",
                      type: "success",
                    });
                    this.dialoguptReport2 = false;
                    this.GetUnqualified();
                  } else {
                    this.$message.error("验证确定失败");
                  }
                })
                .catch(() => {
                  this.$message.error("验证确定失败");
                });
            } else {
              this.$message.error("图片上传失败");
            }
          })
          .catch((error) => {
            console.log("error=" + JSON.stringify(error));
          });
      } else {
        putHeader(
          "/WsmTrackUnqualified/PutFour?id=" +
            this.uptReport2.id +
            "&Content=" +
            this.uptReport2.Content +
            "&isOk=" +
            isOk +
            "&userid=" +
            this.UserId,
          null,
          this.compId
        )
          .then((res) => {
            if (res.result == true) {
              this.$message({
                message: "验证确定完成",
                type: "success",
              });
              this.dialoguptReport2 = false;
              this.GetUnqualified();
              this.ImgFile = [];
            } else {
              this.$message.error("验证确定失败");
            }
          })
          .catch(() => {
            this.$message.error("验证确定失败");
          });
      }
    },
    UploadFile(file) {
      var formData = new FormData();
      formData.append("files", file.file);
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios
        .post("http://192.168.2.122/api/UpLoadFiles", formData, config)
        .then((res) => {
          this.ImgFile.push({
            oldName: res.data.otherValue,
            NewName: res.data.value,
          });
        })
        .catch((res) => {
          console.log(res);
        });
    },
    handleRemove(file) {
      var nIndex = this.ImgFile.findIndex((e) => e.oldName == file.name);
      this.ImgFile.splice(nIndex);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.ImgVisible = true;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      var filterRes = this.ImgFile.filter((f) => f.oldName === file.name);
      if (filterRes.length > 0) {
        this.$message.error("该图片已存在，请勿重复上传!");
        return false;
      }
      return isJPG && isLt2M;
    },
    selUnquaImg(id) {
      getHeader("/WsmTrackUnquaPic/" + id, null, this.compId)
        .then((resp) => {
          this.ShowPicList = [];
          if (resp.result == true) {
            if (resp.value.length == 0) {
              this.$message({
                message: "该跟踪项未上传证据图片!",
                type: "warning",
              });
            } else {
              this.dialogPic = true;
              resp.value.forEach((e) => {
                this.ShowPicList.push({
                  id: e.id,
                  src: "http://192.168.2.122/" + e.picNameAndAddr,
                });
              });
            }
          }
        })
        .catch((error) => {
          console.log("error=" + JSON.stringify(error));
        });
    },
    ckpic(src) {
      this.ImgVisible1 = true;
      this.dialogImageUrl1 = src;
    },
  },
};
</script>

<style>
.div_pageunqualit {
  width: 100%;
}
.div_unqualit {
  width: 95%;
  height: 98%;
  margin-left: 43px;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  box-shadow: 0px 0px 1px 0px rgba(72, 72, 72, 0.17);
}
.div_unqua_hr hr {
  width: 97.4%;
  margin-left: 23px;
  border: 2px solid #eff0f1;
}
.div_unqua_btn {
  width: 100%;
  height: 48px;
}
.div_unqua_btn .ul_btn {
  float: left;
  height: 100%;
}
.div_unqua_btn .ul_btn li {
  float: left;
  list-style-type: none;
  border-radius: 1px;
  height: 100%;
  margin-left: 34px;
  line-height: 48px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.div_unqua_btn .ul_btn li:first-child {
  margin-left: 50px;
}
.ul_unquabtn {
  float: right;
  height: 40px;

  padding: 0px;
  margin: 0px;
}
.ul_unquabtn li {
  float: left;
  margin-left: 10px;
  height: 100%;
  line-height: 40px;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
.ul_unquabtn li span {
  display: block;
  height: 100%;
  text-align: center;
  margin-top: 3px;
  margin-right: 10px;
}
.btn_UptReport {
  width: 106px;
  background: #e9f7ff;
  border: 1px solid #7caffc;
  border-radius: 4px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #7caffc;
}
.btn_AllSend {
  width: 113px;
  background: #ffa400;
  border-radius: 4px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
.div_unquatab {
  width: 97.7%;
  margin-left: 22px;
  margin-top: 10px;
}
.tab_unqua thead tr th {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.unquaPage {
  float: right;
  position: relative;
  padding-top: 10px;
  width: 100%;
  left: 1px;
  background-color: #fff;
}
.btn_send {
  display: block;
  width: 94px;
  height: 23px;
  background: #578cfd;
  border-radius: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.btn_zjck {
  display: block;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #368df1;
}
.txtdesc {
  width: 281px;
  height: 138px;
  background: #eef2f1;
  outline: none;
  border: 0;
  resize: none;
  overflow-x: hidden;
  word-break: break-all;
  margin-left: 3px;
  float: left;
  margin-bottom: 25px;
}
.txtdesc:focus {
  outline: none;
}
.txtdesc ::selection {
  background: #eef2f1;
}
.txtdesc ::-moz-selection {
  background: #eef2f1;
}
.p_DetailDesc1 {
  float: left;
  width: 90px;
  margin-left: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #424242;
}
.el-button .el-button--info:hover {
  background-color: #ef9e6f;
  border-color: #ef9e6f;
}
.span_qx {
  display: block;
  width: 110px;
  height: 48px;
  background: #eef2f1;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666666;
  float: left;
  text-align: center;
  line-height: 48px;
  margin-left: 80px;
}
.span_qd {
  display: block;
  float: left;
  background: #368df1;
  width: 110px;
  height: 48px;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 48px;
  margin-left: 35px;
}
.span_qx1 {
  display: block;
  width: 110px;
  height: 48px;
  background: #eef2f1;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666666;
  float: left;
  text-align: center;
  line-height: 48px;
  margin-left: 40px;
}
.span_qd1 {
  display: block;
  float: left;
  background: #368df1;
  width: 110px;
  height: 48px;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 48px;
  margin-left: 35px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 74px;
  height: 65px;
  line-height: 68px;
  margin-left: 3px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar {
  width: 100%;
  height: 100%;
  display: block;
}
.avatar-uploader /deep/ .el-upload--picture-card {
  width: 74px;
  height: 65px;
}
.avatar-uploader /deep/ .el-upload {
  width: 74px;
  height: 65px;
  line-height: 65px;
}
.avatar-uploader /deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 74px;
  height: 65px;
  line-height: 65px;
}
.avatar-uploader
  /deep/
  .el-upload-list--picture-card
  .el-upload-list__item-thumbnail {
  width: 74px;
  height: 65px;
  line-height: 65px;
}
.avatar-uploader /deep/ .avatar {
  width: 74px;
  height: 65px;
}
.PicList {
  width: 74px;
  height: 65px;
  float: left;
  margin-left: 3px;
  margin-bottom: 3px;
}
.PicList img {
  width: 100%;
  height: 100%;
}
</style>